import React,{useState} from 'react';
import AddService from '../../pages/Admin/AddService';
import { toast } from 'react-toastify';
import {createService} from '../../backend/APIS';
function AddServiceModal({openModel,closeModal}) {
    const [formData,setFormData]=useState({});
    const [image,setImage]=useState("");
    const handleFormChange=(event)=>{
      let value=event.target.value;
      let name=event.target.name;
      setFormData((prevValue)=>{
        return {...prevValue,[name]:value}
    })
   }
   const handleAddService=(event)=>{
    event.preventDefault();
    console.log("formData:",formData);
    if(Object.keys(formData).length<2 || image===''){
        toast.error('🦄 All Fields are required!');
    }else if(formData.serviceTitle!=='' && formData.serviceContent!=='' && image!==''){
        console.log("form data:",formData,image);
        const data=new FormData();
        data.append("serviceTitle",formData.serviceTitle);
        data.append("serviceContent",formData.serviceContent);
        data.append("serviceImage",image);
        const header={
            "Content-type": "multipart/form-data"
        }
        createService(data,header).then((response)=>{
            console.log("response:",response);
            closeModal();
            toast.success('🦄 Service created successfully!');
        }).catch((error)=>{
            console.log("Error:",error);
            toast.error('🦄 Failed to create service!');
            closeModal();
        });
    }else{
        toast.error('🦄 All Fields are required!');
    }
   }
    const closeModel=()=>{
        console.log("modal closed");
        closeModal()
    }
    return (
        <div>
            <div className={`${openModel ? 'block' : 'hidden'
                }`}>
            <div id="static-modal" data-modal-backdrop="static" tabIndex="-1" aria-hidden="true" className="z-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bg-black/30 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4  w-2/5 max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Add Service
                            </h3>
                            <button onClick={()=>closeModel()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <AddService formData={formData} handleAddService={handleAddService} handleFormChange={handleFormChange} setImage={setImage} image={image}/>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default AddServiceModal