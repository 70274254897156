import axios from 'axios';

export const commonRequest=async(methods,url,body,header)=>{
    console.log("body",body);
    console.log("header: ",header);
    let config={};
    if(methods==='DELETE'){
        config={
            method:methods,
            url:url,
            headers:header ? header : {"Content-Type":"application/json"},
        }
    }else{
        config={
            method:methods,
            url:url,
            data:body,
            headers:header ? header : {"Content-Type":"application/json"},
        }
    }
console.log("config",config);
// axios instance
return axios(config).then((response)=>{
    return response;
}).catch((error)=>{
    return error;
})
}