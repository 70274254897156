import React  from 'react';
function UpdateMainService({formData,handleUpdateMainService,handleFormChange}) {
  return (
    <div>
<form className="max-w-sm mx-auto">
  <label htmlFor="email-address-icon" className=" block mb-2 text-sm font-medium text-gray-900 dark:text-white">Main Service Title</label>
  <div className="relative">
    <input type="text" value={formData.main_service_title} onChange={(event)=>handleFormChange(event)} id="email-address-icon" name="main_service_title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-2 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Service Title"/>
  </div>
  <div className="relative mt-3">
<label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Main Service Content</label>
<textarea id="message" name="main_service_content" value={formData.main_service_content} onChange={(event)=>handleFormChange(event)} rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your contents here..."></textarea>
  </div> 
  <button type="submit" onClick={handleUpdateMainService} className="mt-5 w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
  <span className="relative px-5 w-full py-2.5 block transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
  Update
  </span> 
  </button>
</form>
  </div>
  )
}

export default UpdateMainService