import Router from "./routes/Routes";
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { AuthContextProvider } from "./context/AuthContext/AuthContext";
function App() {
  return (
    <div className="App dark:bg-gray-800 min-h-screen">
      <AuthContextProvider>
       <ToastContainer 
       position="top-center"
       autoClose={5000}
       hideProgressBar={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
       />
      <Router />
       </AuthContextProvider>
    </div>
  )
}

export default App