import { commonRequest } from "./APICALL";
import { BASE_URL } from "./helper";

export const getServicesData = async () => {
  return await commonRequest("GET", `${BASE_URL}/get/all/services`, null, null);
}

export const createService = async (body, header) => {
  console.log("body", body);
  return await commonRequest("POST", `${BASE_URL}/add/services`, body, header);
}
export const getUsersHistoryData = async () => {
  return await commonRequest("GET", `${BASE_URL}/get/contact/details`, null, null);
}
export const submitContactDetails = async (body) => {
  return await commonRequest("POST", `${BASE_URL}/submit/contact/details`, body, null);
}
export const getAllMainServices = async () => {
  return await commonRequest("GET", `${BASE_URL}/get/all/main/services`, null, null);
}
export const updateMainServices = async (body, id) => {
  return await commonRequest("PUT", `${BASE_URL}/update/main/services/${id}`, body, null);
}
export const deleteService = async (id) => {
  return await commonRequest("DELETE", `${BASE_URL}/delete/services/${id}`, null, null);
}
export const updateServices = async (body, header, id) => {
  return await commonRequest("PATCH", `${BASE_URL}/update/services/${id}`, body, header);
}
export const submitLoginDetails = async (body) => {
  return await commonRequest("POST",`${BASE_URL}/login`,body,null);
};
