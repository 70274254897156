
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
import FooterContact from "../components/FooterContact";
import { useEffect, useState } from "react";
const PublicLayout = () => {
  const [theme,setTheme]=useState(null);
  const location = useLocation();
  
  useEffect(()=>{
    if(window.matchMedia("(prefers-color-scheme: dark)").matches){
      setTheme("dark");
    }else{
      setTheme("light");
    }
  },[])
  useEffect(()=>{
    if(theme === "dark") {
      document.documentElement.classList.add("dark") 
    }else{ 
      document.documentElement.classList.remove("dark")
  }
  },[theme])

  const handleThemeSwitch = () =>{
    setTheme(theme === "dark" ? "light" : "dark")
  }
  const [isOpenModel,setIsOpenModel] =useState(false);

  const openModal=()=>{
    setIsOpenModel(true);
  }
  const closeModal = () => {
   setIsOpenModel(false);
  };

  return (
    <div className="pb-4">
      <NavBar ToggleBtnVal={theme} setToggleBtnVal={handleThemeSwitch} OpenContactModal={openModal}/>
       <Outlet />
       {!location.pathname.startsWith("/admin") && <FooterContact ToggleContactModalVal={isOpenModel} OpenContactModal={openModal} CloseContactModal={closeModal}/>}
    </div>
  )
}

export default PublicLayout