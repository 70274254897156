export default class Validataions {
   static checkEmail(email) {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
         return true;
      } else {
         return false;
      }
   }
   static checkPhone(contact) {
      if (/^\d{10,11}$/.test(contact)) {
         return true
      } else {
         return false;
      }
   }
   static checkPasswordLength(name, minLength) {
      if (name.length < minLength) {
         return false;
      } else {
         return true;
      }
   }
}