import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import Img1 from "../../../assets/Images/Img1.jpeg";
import Img2 from "../../../assets/Images/Img2.jpeg";
import Img3 from "../../../assets/Images/Img3.jpeg";
import ServiceComponent from "../../../components/ServiceComponent";
import About from "../../../components/About";
// import OurTeam from "../../../components/OurTeam";
const Home = () => {
  const corousalImgArray = [Img1, Img2, Img3];

  return (
    <div className="flex flex-col items-center">
      <Splide
        aria-label="Carousel"
        className="carousel bg-black w-11/12 h-[30rem] rounded-lg"
        options={{
          type: 'fade',
          rewind: true, // Optional: If you want the slides to rewind when reaching the end.
          autoplay: true, // Optional: If you want the slideshow to start automatically.
          interval: 3000, // Optional: Interval between slide change in milliseconds.
          pauseOnHover: true, // Optional: Pause autoplay when hovering over the carousel.
        }}
      >
        {corousalImgArray.map((image,index) => (
           <SplideSlide key={index} style={{ backgroundImage: `url(${image})` }} className="w-full h-[30rem] bg-cover bg-center rounded-lg" >
          </SplideSlide>
        ))}
      </Splide>
      <ServiceComponent/>
      {/* <OurTeam /> */}
      <About />
    </div>
  );
};

export default Home;
