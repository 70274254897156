import Validataion from './Validation';
export default class ContactValidation{
    constructor(email, contact,whatsapp){
        this.email = email;
        this.contact = contact;
        this.whatsapp = whatsapp;
    }
    checkValidations(){
        let errors=[];
        if(!Validataion.checkEmail(this.email)){
            errors['email']='Invalid email';
        }
        if(!Validataion.checkPhone(this.contact)){
            errors['contact']='Please enter a valid mobile number.';
        }
        if(!Validataion.checkPhone(this.whatsapp)){
            errors['whatsapp']='Please enter a valid whatsapp number.';
        }
        return errors;
    }
}