import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn,FaInstagram,FaTwitter } from "react-icons/fa";
import ContactModel from "../models/Public/ContactModel";
import {useState } from "react";
import { appName } from "../config/AppConfigs";
const FooterContact = ({ToggleContactModalVal,OpenContactModal,CloseContactModal}) => {
  
  return (
    <footer className="bg-bg-blacks shadow dark:bg-gray-900 mx-auto my-7 px-5 py-4 w-11/12 rounded-lg">
     {ToggleContactModalVal && <ContactModel openModel={OpenContactModal} closeModal={CloseContactModal}/>}
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <section className="logo-title flex gap-2 justify-center items-center dark:text-white">
            {/* <img src={Union} alt="logo" className="size-10" /> */}
            <h1 className="text-lg font-semibold dark:text-white">{appName}</h1>
          </section>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li data-modal-target="static-modal" data-modal-toggle="static-modal">
              <a href="#" className="text-white hover:underline me-4 md:me-6">About</a>
            </li>
            <li>
              <a href="#" className="text-white hover:underline me-4 md:me-6">Privacy Policy</a>
            </li>
            <li>
              <a href="#" className="text-white hover:underline me-4 md:me-6">Licensing</a>
            </li>
            <li>
              <span onClick={OpenContactModal} className="cursor-pointer text-white hover:underline me-4 md:me-6">Contact</span>
            </li>
          </ul>
        </div>
        <hr className="mt-3 border-gray-200 sm:mx-auto dark:border-gray-700 lg:mt-3" />
        <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
          <span className="block text-sm text-white text-center dark:text-gray-400 mt-3">© 2024 <a href="https://flowbite.com/" className="hover:underline">{appName}</a>. All Rights Reserved.</span>
          <ul className="flex flex-wrap gap-6 ml-7 items-center text-sm font-medium text-white mt-3">
            <li>
            <a href="#" className="hover:underline me-4 md:me-6">
                <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                  <FaLinkedinIn />
                  <span className="sr-only">Icon description</span>
                </button>
              </a>
            </li>
            <li>
            <a href="#" className="hover:underline me-4 md:me-6">
                <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                  <FaFacebookF />
                  <span className="sr-only">Icon description</span>
                </button>
              </a>
            </li>
            <li>
            <a href="#" className="hover:underline me-4 md:me-6">
                <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                  <FaTwitter />
                  <span className="sr-only">Icon description</span>
                </button>
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline me-4 md:me-6">
                <button type="button" className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                  <FaInstagram />
                  <span className="sr-only">Icon description</span>
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default FooterContact