import { Navigate, useRoutes } from "react-router-dom";

import PublicLayout from "../layouts/PublicLayout";
import AdminLayout from "../layouts/AdminLayout";
import Home from "../pages/Public/Home/Home";
import AdminHome from "../pages/Admin/Home";
import ContactModel from "../models/Public/ContactModel";
import UserHistory from "../pages/Admin/UserHistory";
import Login from "../pages/Admin/Login";
const Routes = () => {
  const public_routes = [
    { path: "", element: <Home /> },
    {
      path: "contact",
      element: <ContactModel />,
    },
    { path: "admin/login", element: <Login /> },

  ];
  const admin_routes = [
    { path: "dashboard", element: <AdminHome /> },
    { path: "dashboard/user/requests", element: <UserHistory /> },
    { element: <Navigate to="/admin/login" />, index: true },
  ];

  const routes = useRoutes([
    {
      path: "/admin",
      element: <AdminLayout />,
      children: admin_routes,
    },
    {
      path: "/",
      element: <PublicLayout />,
      children: public_routes,
    },
    {
      path: "*",
      element: <Navigate to="/" />, // Redirect to the root path
    },
  ]);
  return routes;
};

export default Routes;
