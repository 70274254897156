import React, { useState, useEffect } from 'react'
import { getServicesData } from '../../backend/APIS';
import AddServiceModal from '../../models/Admin/AddServiceModal';
import PreviewMainServiceModal from '../../models/Admin/PreviewMainServiceModal';
import UpdateMainServiceModal from '../../models/Admin/UpdateMainServiceModal';
import Tooltip from '../../common/Tooltip';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
const Home = () => {
  const [serviceData, setServiceData] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [openAddServiceModal,setOpenAddServiceModal] = useState(false);
  const [openPreviewMainServiceModal,setPreviewMainServiceModal] = useState(false);
  const [openUpdateServiceModal,setUpdateServiceModal] = useState(false);
  useEffect(() => {
    getServiceData();
  }, [])
  const getServiceData = () => {
    getServicesData().then((response) => {
      console.log(response.data);
      setServiceData(response.data);
      setFilteredData(response.data);
    }).catch((error) => {
      console.log("Error:", error);
    })
  }
  useEffect(() => {
    if (search.trim() !== '') {
      let searchedData = serviceData.filter((service) => service.service_title.toLowerCase().includes(search.toLowerCase()) || service.service_content.toLowerCase().includes(search.toLowerCase()));
      setFilteredData(searchedData);
    } else {
      setFilteredData(serviceData);
    }
  }, [search, serviceData])
  const openModal=()=>{
    setUpdateServiceModal(true);
  }
  const closeModal = () => {
    setUpdateServiceModal(false);
   };
  const openPreviewServiceModal=()=>{
    setPreviewMainServiceModal(true);
  }
  const closePreviewServiceModal=()=>{
    setPreviewMainServiceModal(false);
  }
  const openAddServicModal=()=>{
    setOpenAddServiceModal(true);
  }
  const closeAddServicModal=()=>{
    setOpenAddServiceModal(false);
  }
  const handleUpdateService=(item)=>{
console.log("item:",item);
  }
  const handleDeleteService=(item)=>{
    console.log("item:",item);
  }
  return (
    <div className="flex flex-col mx-16 w-11/12">
      {openAddServiceModal && <AddServiceModal openModel={openAddServiceModal} closeModal={closeAddServicModal}/>}
      {openPreviewMainServiceModal && <PreviewMainServiceModal openModel={openPreviewMainServiceModal} closeModal={closePreviewServiceModal}/>}
      {openUpdateServiceModal && <UpdateMainServiceModal openModel={openUpdateServiceModal} closeModal={closeModal}/>}
      <div className='text-right my-2'>
        <button onClick={openAddServicModal} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Add Service
          </span>
        </button>
        <button onClick={openModal} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Update Main Service
          </span>
        </button>
        <button onClick={openPreviewServiceModal} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">
<span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
Preview Main Service
</span>
</button>
      </div>
      <div className="max-w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="py-2 px-4 flex justify-between items-center bg-white dark:bg-gray-900">
          <span className="text-xl ml-2 font-semibold text-gray-500 dark:text-white">Total:{serviceData.length}</span>
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="text" value={search} onChange={(event) => setSearch(event.target.value)} id="table-search" className="block pt-2 pb-2 ps-10 text-sm text-gray-900 border rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search here..." />
          </div>
        </div>
        {/* table code */}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Service Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Service Content
                </th>
                <th scope="col" className="px-6 py-3">
                  Service Image
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <h1 className='w-full text-lg text-center text-navName py-2'>Data Not Found</h1>
                  </td>
                </tr>
              )}
              {filteredData.length > 0 && filteredData.map((item, index) => {
                return <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.serviceTitle}
                  </th>
                  <td className="px-6 py-4">
                    {item.serviceContent}
                  </td>
                  <td className="px-6 py-4">
                    
                    <img className='w-10 h-auto rounded-lg' src={item.serviceImage} alt="img" />
                  </td>
                  <td className="px-6 py-4 text-right">
                  <Tooltip text="Edit" topHeight="55px">
                    <button onClick={()=>handleUpdateService(item)}><FaRegEdit size="20" className='text-text-primary'/></button>
                    </Tooltip>
                    <Tooltip text="Delete" topHeight="55px">
                    <button onClick={()=>handleDeleteService(item)} className='ml-2'><MdDelete size="20" className='text-navName'/></button>
                    </Tooltip>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        {/* table code till here */}
      </div>

    </div>
  )
}

export default Home