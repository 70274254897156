import React,{useEffect,useState} from 'react';
import {getUsersHistoryData} from '../../backend/APIS';
function UserHistory() {
  const [userHistoryData, setUserHistoryData] = useState([]);
  const [search,setSearch]=useState('');
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    getUserHistoryData();
  }, [])
  const getUserHistoryData = () => {
    getUsersHistoryData().then((response) => {
      console.log(response.data);
      setUserHistoryData(response.data);
      setFilteredData(response.data);
    }).catch((error) => {
      console.log("Error:", error);
    })
  }
  useEffect(() => {
    if (search.trim() !== '') {
      const searchedData = userHistoryData.filter((user) =>
        user.email.toLowerCase().includes(search.toLowerCase()) || user.contact.includes(search) || user.whatsapp.includes(search) || user.description.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(searchedData);
    } else {
      setFilteredData(userHistoryData);
    }
  }, [search,userHistoryData]);
  return (
    <div className="flex flex-col mx-16 w-11/12 my-5">
      <div className="max-w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className="py-2 px-4 flex justify-between items-center bg-white dark:bg-gray-900">
          <span className="text-xl ml-2 font-semibold text-gray-500 dark:text-white">Total:{userHistoryData.length}</span>
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="text" value={search} onChange={(event)=>setSearch(event.target.value)} id="table-search" className="block pt-2 pb-2 ps-10 text-sm text-gray-900 border rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search here..." />
          </div>
        </div>
        {/* table code */}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Contact
                </th>
                <th scope="col" className="px-6 py-3">
                  Whatsapp
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
            {filteredData.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <h1 className='w-full text-lg text-center text-navName py-2'>Data Not Found</h1>
                  </td>
                </tr>
              )}
              {filteredData.length>0 && filteredData.map((item,index)=>{
               return <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {item.email}
                </th>
                <td className="px-6 py-4">
                {item.contact}
                </td>
                <td className="px-6 py-4">
                  {item.whatsapp}
                </td>
                <td className="px-6 py-4">
                  {item.description}
                </td>
                <td className="px-6 py-4">
                  {item.datecreated}
                </td>
              </tr>
              })}
            </tbody>
          </table>
        </div>
        {/* table code till here */}
      </div>

    </div>
  )
}

export default UserHistory