import React, { useState, useEffect } from 'react'
import { getServicesData,getAllMainServices } from '../backend/APIS';
const ServiceComponent = () => {
  const [serviceData, setServiceData] = useState([]);
  const [mainService,setMainService] = useState({});
  // const [preview, setPreview] = useState("");
  useEffect(() => {
    getServiceData();
    getAllMainService();
  }, [])
  const getServiceData = () => {
    getServicesData().then((response) => {
      console.log("response:",response.data);
      setServiceData(response.data);
      console.log("serviceData",serviceData,mainService);
    }).catch((error) => {
      console.log("Error:", error);
    })
  }
  const getAllMainService=()=>{
    getAllMainServices().then((response) => {
      console.log("response:",response.data[0]);
      setMainService(response.data[0])
    }).catch((error) => {
      console.log("Error:", error);
    })
  }
  return (
    <div id='services' className="flex flex-col bg-bg-blacks dark:bg-gray-900 mx-16 my-7 px-2 py-4 w-11/12 rounded-lg">
      <div>
        <h1 className="text-2xl font-semibold ml-3 text-white">Services</h1>
      </div>
      <div>
        <h1 className="text-lg font-semibold text-gray-200 mt-2 ml-3">{mainService.mainServiceTitle}</h1>
      </div>
      <div>
        <h4 className="font-semibold text-gray-200 mt-1 ml-3">{mainService.mainServiceContent}</h4>
      </div>
      <div className='flex justify-start gap-x-2.5 flex-wrap mt-3 relative overflow-hidden'>
        {serviceData && serviceData.length>0 && serviceData.map((item,index)=>{
          return <div key={index} className='mt-7 flex flex-col md:flex-row gap-5 ml-2 w-full hover:scale-[1.01] hover:duration-75 ease-in-out bg-gray-200 border border-gray-200 rounded-xl shadow hover:bg-white dark:bg-gray-800 dark:border-gray-900 dark:text-white text-gray-900 p-4 hover:rounded-sm'>
             <div className='w-full md:w-[40%]'> 
             <img className='w-full h-[350px] bg-cover rounded-lg' src={item.serviceImage} alt={`serviceImage${index}`} />
             </div>
          <div className='w-full md:w-[60%]'>
          <h1 className="text-xl font-semibold mt-2 mb-1">{item.serviceTitle}</h1>
          <h4 className='text-justify'>{item.serviceContent}</h4>
          </div>
          </div>
        })}
      </div>
    </div>
  )
}

export default ServiceComponent