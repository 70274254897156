import React  from 'react';
function AddService({formData,handleAddService,handleFormChange,setImage,image}) {
  return (
    <div>
<form className="max-w-sm mx-auto">
  <label htmlFor="email-address-icon" className=" block mb-2 text-sm font-medium text-gray-900 dark:text-white">Service Title</label>
  <div className="relative">
    <input type="text" value={formData.serviceTitle} onChange={(event)=>handleFormChange(event)} id="email-address-icon" name="serviceTitle" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-2 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Service Title"/>
  </div>
  <div className="relative mt-3">
<label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Service Content</label>
<textarea id="message" name="serviceContent" value={formData.serviceContent} onChange={(event)=>handleFormChange(event)} rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your contents here..."></textarea>
  </div>
  <label htmlFor="message" className="block mt-3 text-sm font-medium text-gray-900 dark:text-white">Service Image</label>
  <div className="flex items-center justify-center w-full mt-2">
    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-28 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
             <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG</p>
            {/* <p className="text-sm font-semibold text-gray-500 dark:text-gray-400">{image}</p> */}
        </div>
        <input id="dropzone-file" type="file" name="serviceImage" onChange={(event)=>setImage(event.target.files[0])} />
    </label>
</div> 
  <button type="submit" onClick={handleAddService} className="mt-5 w-full relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
  <span className="relative px-5 w-full py-2.5 block transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
  Add
  </span> 
  </button>
</form>
  </div>
  )
}

export default AddService