import React, { useState } from "react";

const AuthContext = React.createContext({
    isLoggedIn: false,
    login: () => { },
    
    logout: () => { },


    // createUserProfile: (id)=>{ },
    // user_profile_id:null,
    
});

export const AuthContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const [user_profile_id, setUser_profile_id] = useState(null);


    // const createUserProfile = (id) => {
    //     // console.log(id,"id");
    //     setUser_profile_id(id)
    // }
    


    const loginHandler = () => {
        setIsLoggedIn(true);
    };

    const logoutHandler = () => {
        setIsLoggedIn(false);
    };

    const contextValue = {
        isLoggedIn: isLoggedIn,
        login: loginHandler,

        logout: logoutHandler,
        
        // createUserProfile : createUserProfile,
        // user_profile_id : user_profile_id,
    };


    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;